import { Flex, Text, Box, ExternalLink, visualRefreshTheme as theme } from '@mm/company-ui'
import React from 'react'

export const FrontFooter = () => {
  return (
    <footer>
      <Box sx={{ width: '100%' }}>
        <Flex
          row
          sx={{
            width: '100%',
            borderTop: '1px solid',
            borderTopColor: 'greyscale.100',
          }}
        >
          <Flex justify="space-between" align="center" sx={{ marginY: 2, width: '100%' }}>
            <Text
              variant="body"
              as="span"
              sx={{ color: 'greyscale.500', fontSize: [theme.fontSizes[0], theme.fontSizes[1]] }}
            >
              ©2022 Mochary Method
            </Text>
            <Flex>
              <ExternalLink
                href="/terms"
                variant="secondary"
                sx={{ fontSize: [theme.fontSizes[0], theme.fontSizes[1]] }}
              >
                Terms of Service
              </ExternalLink>
              <ExternalLink
                href="/privacy"
                variant="secondary"
                sx={{ marginLeft: [1, 2], fontSize: [theme.fontSizes[0], theme.fontSizes[1]] }}
              >
                Privacy Policy
              </ExternalLink>
              <ExternalLink
                href="mailto:hello@mocharymethod.com"
                variant="secondary"
                sx={{ marginLeft: [1, 2], fontSize: [theme.fontSizes[0], theme.fontSizes[1]] }}
              >
                Contact
              </ExternalLink>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </footer>
  )
}
