import { Button, Flex, Input, Modal, FormItem, Text, Box } from '@mm/company-ui'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { auth } from '../../firebase'
import { CheckCircleIcon, ExclamationCircleIcon } from '@mm/company-ui-icons'

type ForgotPasswordModalProps = {
  isOpen: boolean
  onRequestClose: () => void
}
type FormFields = {
  email: string
}
export const ForgotPasswordModal = ({ isOpen, onRequestClose }: ForgotPasswordModalProps) => {
  const [loading, setLoading] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const [error, setError] = React.useState(false)
  const form = useForm<FormFields>({ mode: 'onTouched' })

  const onSubmit = React.useCallback(
    form.handleSubmit(async ({ email }) => {
      try {
        setError(false)
        setLoading(true)
        setMessage('')
        await auth.sendPasswordResetEmail(email)
        setLoading(false)
        setMessage(`${email}`)
      } catch (error) {
        let message = 'Unknown Error'
        if (error instanceof Error) message = error.message
        console.log(message)
        setLoading(false)
        setMessage(message)
        setError(true)
      }
    }),
    [form.handleSubmit],
  )

  return (
    <Modal isOpen={isOpen} title="Forgot Password" showCloseIcon onRequestClose={onRequestClose}>
      <FormProvider {...form}>
        <Flex column gap={2}>
          <Flex column>
            <FormItem
              name="email"
              label="Your e-mail"
              rules={{
                required: 'Email is required',
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Invalid email format.',
                },
              }}
            >
              <Input variant="default" placeholder="Email" />
            </FormItem>
            <Box sx={{ marginTop: 1 }}>
              {message && error && (
                <Text variant="body" as="p" sx={{ color: '#DC2222' }}>
                  <ExclamationCircleIcon width={1.5} height={1.5} sx={{ marginRight: 0.5 }} />
                  {message}
                </Text>
              )}
              {message && !error && (
                <Text variant="body" as="p" sx={{ color: '#008246' }}>
                  <CheckCircleIcon width={1.5} height={1.5} sx={{ marginRight: 0.5 }} />
                  Recovery email sent to <strong>{message}</strong>.
                </Text>
              )}
            </Box>
          </Flex>
          <Flex row grow justify="flex-end" gap={2} sx={{ paddingTop: 2 }}>
            <Button variant="secondary" onClick={onRequestClose}>
              Cancel
            </Button>
            <Button loading={loading} onClick={onSubmit} type="submit">
              Send me instructions
            </Button>
          </Flex>
        </Flex>
      </FormProvider>
    </Modal>
  )
}
