import NextHead from 'next/head'
import React from 'react'

export type HeadProps = {
  title?: string
}

export const Head = ({ title }: HeadProps) => {
  const pageTitle = title ? `${title} – Mochary Method` : 'Mochary Method'

  return (
    <NextHead>
      <title>{pageTitle}</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico" />
    </NextHead>
  )
}
