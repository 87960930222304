import { Box, Text, Flex, visualRefreshTheme as theme } from '@mm/company-ui'
import Image from 'next/image'
import React from 'react'
import { LoginForm } from '../modules/login'
import { FrontFrame } from '../modules/navigation'
import { useDispatch, useSelector } from 'react-redux'
import { selectors, actions } from '../modules/auth'
import { useRouter } from 'next/router'

const LoginPage = () => {
  const dispatch = useDispatch()
  const router = useRouter()
  const { email, temp } = router.query
  const initialized = useSelector(selectors.getInitialized())
  const user = useSelector(selectors.getUser())

  React.useEffect(() => {
    if (initialized && !user && temp && email) {
      // Sign in user if parameters are provided in url
      dispatch(
        actions.signInRequest({
          email: decodeURI(email as string),
          password: decodeURI(temp as string),
          redirectTo: '/onboarding/update-password',
        }),
      )
    }
  }, [initialized, user, router.query])

  return (
    <FrontFrame title="Login" logoOnly>
      <Flex
        sx={{
          width: '100%',
          height: '100%',
          justifyContent: ['start', 'space-between'],
          flexDirection: ['column-reverse', 'row'],
          alignItems: ['start', 'center'],
        }}
      >
        <Box
          sx={{
            width: ['100%', '750px'],
            marginTop: [4, -8],
            marginRight: [0, 8],
            marginLeft: [-2, 8],
            position: 'relative',
            zIndex: -1,
          }}
        >
          <Image alt="Login Image" src="/login.svg" layout="responsive" height={694} width={593} />
        </Box>

        <Box sx={{ width: ['100%', 400], maxWidth: 400, margin: 'auto' }}>
          <Text variant="jumbo" as="h1" sx={{ fontWeight: 'bold', fontSize: [theme.fontSizes[5], theme.fontSizes[7]] }}>
            Sign In
          </Text>
          <LoginForm />
        </Box>
      </Flex>
    </FrontFrame>
  )
}

export default LoginPage
