import { Flex, Button, Box } from '@mm/company-ui'
import { MocharyFullLogoIcon, ExternalLink } from '@mm/company-ui-icons'
import Link from 'next/link'
import React from 'react'
import { Head } from './Head'

export type FrontHeaderProps = {
  logoOnly?: boolean
  title?: string
}

export const FrontHeader = ({ title, logoOnly = false }: FrontHeaderProps) => {
  return (
    <>
      <Head title={title} />
      <header>
        <Flex row sx={{ width: '100%' }}>
          <Flex
            justify={logoOnly ? 'center' : 'space-between'}
            align="center"
            sx={{ marginY: 2, marginX: [2, 8], width: '100%' }}
          >
            <Box sx={{ cursor: 'pointer' }}>
              <Link href="/">
                <MocharyFullLogoIcon width={3} height={3} color="darkBlue" />
              </Link>
            </Box>
            {!logoOnly && (
              <Flex>
                <Button
                  size="small"
                  variant="tertiary"
                  endIcon={<ExternalLink />}
                  onClick={() => window.open('/curriculum', '_blank')}
                  sx={{ fontWeight: 600 }}
                >
                  Curriculum
                </Button>
                <Link href="/login">
                  <Button size="small" variant="secondary" sx={{ marginLeft: [2, 4], fontWeight: 600 }}>
                    Login
                  </Button>
                </Link>
              </Flex>
            )}
          </Flex>
        </Flex>
      </header>
    </>
  )
}
