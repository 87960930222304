import { Box, Button, Input, ExternalLink, FormItem, Text, Flex } from '@mm/company-ui'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { ForgotPasswordModal } from './ForgotPasswordModal'
import { actions, selectors, PageTypes } from '../../auth/store'
import { useRouter } from 'next/router'
import { ExclamationCircleIcon } from '@mm/company-ui-icons'

type FormFields = {
  email: string
  password: string
}
export const LoginForm = () => {
  const [forgotPasswordOpen, setForgotPasswordOpen] = React.useState(false)
  const router = useRouter()
  const dispatch = useDispatch()
  const error = useSelector(selectors.getError(PageTypes.LOGIN))
  const loading = useSelector(selectors.getLoading(PageTypes.LOGIN))
  const form = useForm<FormFields>({ mode: 'onTouched' })

  const onSubmit = React.useCallback(
    form.handleSubmit(({ email, password }) => {
      dispatch(
        actions.signInRequest({
          email,
          password,
          redirectTo: router.query.redirect ? decodeURI(router.query.redirect as string) : undefined,
        }),
      )
    }),
    [form.handleSubmit],
  )

  return (
    <Box>
      <FormProvider {...form}>
        <FormItem
          name="email"
          label="Email"
          rules={{
            required: 'Email is required',
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: 'Invalid email format.',
            },
          }}
        >
          <Input name="email" type="email" placeholder="Email" />
        </FormItem>

        <FormItem name="password" label="Password" rules={{ required: 'Password is required' }}>
          <Input name="password" type="password" placeholder="Password" />
        </FormItem>

        <Button
          loading={loading}
          sx={{ width: '100%', marginTop: 2, marginBottom: 2, display: 'flex', justifyContent: 'center' }}
          onClick={onSubmit}
          type="submit"
        >
          Log In
        </Button>
        {error && (
          <Text variant="body" as="p" sx={{ color: '#DC2222' }}>
            <ExclamationCircleIcon width={1.5} height={1.5} sx={{ marginRight: 0.5 }} />
            {error.message}
          </Text>
        )}

        <ExternalLink
          variant="secondary"
          onClick={() => {
            setForgotPasswordOpen(true)
          }}
        >
          Forgot Password?
        </ExternalLink>
      </FormProvider>
      <ForgotPasswordModal
        isOpen={forgotPasswordOpen}
        onRequestClose={() => {
          setForgotPasswordOpen(false)
        }}
      />
    </Box>
  )
}
