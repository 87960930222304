import { Flex, Box } from '@mm/company-ui'
import React from 'react'
import { FrontFooter } from './FrontFooter'
import { FrontHeader, FrontHeaderProps } from './FrontHeader'
import { ThemeProvider } from '@emotion/react'
import { visualRefreshTheme } from '@mm/company-ui'

export type FrontFrameProps = {
  children: React.ReactNode
}

export const FrontFrame = ({ children, title, logoOnly }: FrontFrameProps & FrontHeaderProps) => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <ThemeProvider theme={visualRefreshTheme}>
      <Flex
        column
        sx={{
          height: '100%',
          width: '100%',
          maxWidth: '1600px',
          margin: 'auto',
          minHeight: '100vh',
          fontWeight: 300,
        }}
      >
        <FrontHeader title={title} logoOnly={logoOnly} />
        <Flex grow column justify="space-between" sx={{ marginX: [2, 8] }}>
          <Box sx={{ height: '100%', paddingY: 2 }}>{children}</Box>
          <FrontFooter />
        </Flex>
      </Flex>
    </ThemeProvider>
  )
}
